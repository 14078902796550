.app-logo {
  height: 40vmin;
  pointer-events: none;
}

.app-header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 900;
}

.app-link {
  color: #61dafb;
}

.app-primary-nav {
  background: rgba(246,246,246,0.8);
}

.app-body {
  background-repeat: no-repeat;
  background-color: #cccccc;
  background-size: cover;
  background-position: center;
  height: 100%;
}

.logo {
  width: 60px;
  padding: 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.clickable {
  cursor: pointer;
}

.logout-el {
  margin-top: 0px;
  padding-top: 0px;
}

.logout-button {
  width: 100%;
  height: 100%;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
