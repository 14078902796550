.sublink-card {
  box-shadow: 0px 0px 0px grey;
}

.sublink-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.sublink-card-disabled {
  box-shadow: 0px 0px 0px grey;
  color: #6c757d !important;
  cursor: not-allowed;
}
